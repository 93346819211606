body{
    margin-top:20px;
    color: #1a202c;
    text-align: left;
    background-color: #e2e8f0;
	font-family: sans-serif;
}
.main-body {
    padding: 15px;
}
.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}

.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}
.mw-page-title-main {
	font-size: 1.8em;
	font-family: 'Linux Libertine','Georgia','Times',serif;
	line-height: 1.3;
}
.vector-body {
    position: relative;
    z-index: 0;
	font-size: 0.875em;
	font-size: calc(2em * 0.775);
	line-height: 1.6;
}
.mw-body-content {
    clear: both;
    content: '';
    display: block;
}
.mw-content-ltr {
    direction: ltr;
}
.infobox {
    border: 1px solid #a2a9b1;
    border-spacing: 3px;
    background-color: #f8f9fa;
    color: black;
    margin: 0.5em 0 0.5em 1em;
    padding: 0.2em;
    float: right;
    clear: right;
    font-size: 88%;
    line-height: 1.5em;
    width: 22em;
}
table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
.infobox-label, .infobox-data, .infobox th, .infobox td {
    text-align: left;
	padding-top: 15px;
}
.infobox .infobox-header, .infobox .infobox-subheader, .infobox .infobox-image, .infobox .infobox-full-data, .infobox .infobox-below {
    text-align: center;
}
.infobox .infobox-above, .infobox .infobox-title, .infobox caption {
    font-size: 125%;
    font-weight: bold;
    text-align: center;
}
.vector-body p {
    margin: 0.5em 0;
}
p {
    margin: 0.4em 0 0.5em 0;
	text-align: left;
}
h2 {
	text-transform: unset;
	text-align: left;
}
div.tleft {
    margin: 0.5em 1.4em 1.3em 0;
}
div.thumb {
    width: auto;
    background-color: transparent;
    margin-bottom: 0.5em;
}
div.tleft, div.floatleft, table.floatleft {
    float: left;
    clear: left;
}
div.thumbinner {
    border: 1px solid #c8ccd1;
    background-color: #f8f9fa;
    font-size: 94%;
}
div.thumbinner {
    padding: 3px;
    text-align: center;
    overflow: hidden;
}
.thumbinner {
    min-width: 100px;
}
.mw-content-ltr .thumbcaption {
    text-align: left;
}
.thumbcaption {
    border: 0;
    font-size: 84%;
}
.thumbcaption {
    text-align: left;
    line-height: 1.4em;
    padding: 3px;
}

div.tright {
    margin: 0.5em 0 1.3em 1.4em;
}
div.tright, div.floatright, table.floatright {
    clear: right;
    float: right;
}
.wikitable {
    background-color: #f8f9fa;
    color: #202122;
    margin: 1em 0;
    border: 1px solid #a2a9b1;
    border-collapse: collapse;
}
table {
    font-size: 100%;
}
table {
    border-collapse: separate;
    text-indent: initial;
    border-spacing: 2px;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.wikitable > tr > th, .wikitable > tr > td, .wikitable > * > tr > th, .wikitable > * > tr > td {
    border: 1px solid #a2a9b1;
    padding: 0.2em 0.4em;
}
td {
    display: table-cell;
    vertical-align: inherit;
}
.client-js .sortable:not(.jquery-tablesorter) > * > tr:first-child > th:not(.unsortable), .jquery-tablesorter th.headerSort {
    background-image: url(/w/resources/src/jquery.tablesorter.styles/images/sort_both.svg?0e440);
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 21px;
}
.wonClass {
	background: #9EFF9E; color: #000; vertical-align: middle; text-align: center;
}
.nominatedClass {
	background: #FFE3E3; color: black; vertical-align: middle; text-align: center;
}
.audio {
	white-space:nowrap;margin-right:.25em;
}