.fullinfo
{
    display: flex;
    justify-content: space-around;

    .image 
    {
        display: inline-block;
    }

    .rightsideinfo
    {
        display: flex;
        flex-direction: column;
        width: 40%;

        .assetname 
        {
            font-size: 50px;
            font-family: 'Open Sans', sans-serif;
        }

        .creator_and_owner
        {
            font-size: 15px;
            padding-bottom: 50px;
            display: flex;
            justify-content: space-around;
			font-family: 'Open Sans', sans-serif;

            .creator 
            {
                display: flex;
                flex-direction: column;
            }

        }

        .description 
        {
            font-size: 20px;
            padding-bottom: 50px;
        }
    }
}

.secondcomponent 
{
    display: flex;
    justify-content: space-around;
    .leftcomponent 
    {
        display: flex;
        flex-direction: column;
        width: 40%;
    }

    .rightcomponent 
    {
        width: 40%;
    }
}

.accordion-button {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1.75rem !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video {
	position: inherit;
}
