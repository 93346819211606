ul.social {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

ul.social > li {
  display: inline-block;
}

ul.social > li > a {
  display: inline-block;
  font-size: 18px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 36px;
  background-color: #222222;
  color: #fff;
  margin: 0 3px 3px 0;
}

ul.social > li > a:hover {
  text-decoration: none;
  background-color: #ee7c22;
}