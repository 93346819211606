.spinner
{
    display: flex;
    align-items: center;
    min-height: 100vh;
    animation: rotate_it 5s linear infinite;
    z-index: 10;
    position :fixed;
    left: 45%;
    top: 1vh;
    

    .loader 
    {
        position: relative;
        width: 120px;
        height: 120px;

        span
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: rotate(calc(18deg * var(--i)));
        }
        span::before
        {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: #00ff0a;
            box-shadow: 0 0 10px #00ff0a,
                        0 0 20px #00ff0a;
            animation: reduce_size 1s linear infinite;
            animation-delay: calc(0.05s*var(--i));
        }
        @keyframes reduce_size 
        {
            0% 
            {
                transform: scale(1);
            }   
            80%,100% 
            {
                transform: scale(0);
            } 
        }
            }
}
@keyframes rotate_it 
{
    0%
    {
        filter: hue-rotate(0deg);
    }   
    100%
    {
        filter: hue-rotate(360deg);
    } 
}