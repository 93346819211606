.home-container {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  
  